<template>
  <div class="about">
    <section class="section video-container swiper-slider" id="home" data-loop="false" data-simulate-touch="false"
      data-autoplay="false">
      <div class="video-wrapper backgr opacity">
        <div class="">
          <!-- <img src="/img/home-slider-02.jpeg"> -->
          <div class="slide-caption">
            <div class="container container-wide bg-text">
              <h1 data-caption-animate="fadeInUp" data-caption-delay="300" class="title-weight text-white">
                <b>{{ $t('lang.about.section_1.banner.title') }}</b>
              </h1>
              <h3 data-caption-animate="fadeInUp" data-caption-delay="400" class="text-white">
                {{ $t('lang.about.section_1.banner.subtitle') }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section novi-bg novi-bg-img section-md bg-default text-center">
      <div class="container width-xl">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.about.section_2.title') }}</b>
            </h2>

            <p v-html="$t('lang.about.section_2.description_1')"></p>
            <p v-html="$t('lang.about.section_2.description_2')"></p>
            <p v-html="$t('lang.about.section_2.description_3')"></p>

            <p>
              <span class="font-weight-bold">{{ $t('lang.about.section_2.description_4') }}</span>
              <span class="connecting-brand">{{ $t('lang.about.section_2.description_5') }}</span>
            </p>
            <br />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="grid-section">
        <div class="cultura">
          <div class="grid-cultura">
            <div class="cultura-title">{{ $t('lang.about.section_3.card_1.title') }}</div>

            <div class="cultura-desc">
              {{ $t('lang.about.section_3.card_1.description') }}
            </div>
          </div>
        </div>

        <div class="mision">
          <div class="grid-title">{{ $t('lang.about.section_3.card_2.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.about.section_3.card_2.description') }}
            <img src="@/assets/images/svg/mision.svg" alt="Misión" />
          </div>
        </div>

        <div class="vision">
          <div class="grid-title">{{ $t('lang.about.section_3.card_3.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.about.section_3.card_3.description') }}
            <img src="@/assets/images/svg/ver.svg" alt="Visión" />
          </div>
        </div>

        <div class="valores">
          <div class="grid-title">{{ $t('lang.about.section_3.card_4.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.about.section_3.card_4.description') }}
            <img src="@/assets/images/svg/valor.svg" alt="Responsabilidad" />
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md bg-default text-center">
      <div class="container width-xl">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.about.section_4.title') }}</b>
            </h2>
            <p v-html="$t('lang.about.section_4.description_1')"></p>
            <p v-html="$t('lang.about.section_4.description_2')"></p>
            <p v-html="$t('lang.about.section_4.description_3')"></p>
            <p v-html="$t('lang.about.section_4.description_4')"></p>
            <p>{{ $t('lang.about.section_4.description_5') }}</p>
            <br />
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img bg-default text-center">
      <div class="background-tecn">
        <div class="container">
          <div class="row row-30">
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/1.png" alt="aws" width="126" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/2.png" alt="React" width="134" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/3.png" alt="Node" width="132" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/4.png" alt="Java" width="126" height="102" />
                </a>
              </figure>
            </div>
          </div>
          <div class="row row-30">
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/5.png" alt=".Net" width="126" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/6.png" alt="Microsoft Azure" width="134" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/6-2.png" alt="Terraform" width="132" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/7.png" alt="Jenkins" width="126" height="102" />
                </a>
              </figure>
            </div>
          </div>
          <div class="row row-30">
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/8.png" alt="GitLab" width="126" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/9.png" alt="Dialogflow" width="134" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/10.png" alt="Spring" width="132" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/11.png" alt="MongoDB" width="126" height="102" />
                </a>
              </figure>
            </div>
          </div>
          <div class="row row-30">
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/12.png" alt="hadoop" width="126" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/13.png" alt="mysql" width="134" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/14.png" alt="PostgreSQL" width="132" height="102" />
                </a>
              </figure>
            </div>
            <div class="col-sm-6 col-md-3">
              <figure class="box-icon-image">
                <a href="#">
                  <img src="@/assets/images/logos/15.png" alt="Python" width="126" height="102" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section text-center">
      <div class="row row-fix no-gutters">
        <div class="col-lg-6 novi-bg novi-bg-img cat-wrap bg-accent">
          <div class="cat-mod-1 gestion">
            <!-- <h3>SOFTWARE DE GESTIÓN</h3> -->
            <!-- <div class="comilla">“</div> -->
            <p v-html="$t('lang.about.section_5.description_1')"></p>

            <p v-html="$t('lang.about.section_5.description_2')"></p>

            <p v-html="$t('lang.about.section_5.description_3')"></p>
            <!-- <div class="comilla2">”</div> -->
          </div>
        </div>
        <div class="col-lg-6 novi-bg novi-bg-img image-about top-image">
          <img src="@/assets/images/14.png" alt="Sistema de Gestión de Profesionales y Clientes" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'About',
  metaInfo: {
    title: 'Conecta con el talento que necesitas | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          '🔥 Proporcionamos Talento cuando más lo necesitas✅ Consigue excelentes profesionales para que formes tu gran equipo'
      }
    ]
  }
});
</script>
